import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Job = lazy(() => import("./job/Joblist"));
const Create = lazy(() => import("./job/Create"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));
const Homepage = lazy(() => import("./user-pages/Homepage"));
const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Integrationscreen = lazy(() => import("./user-pages/Integrationscreen"));
const ProjectPage = lazy(() => import("./personal/project"));
const JobInfo = lazy(() => import("./job/jobinformation"));
const Companysettingsscreen = lazy(() => import("./user-pages/Companysettingsscreen"));

class AppRoutes extends Component {
  render() {
    const { selectedCompany } = this.props;

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register1} />
          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />
          {selectedCompany ? (
            <>
              <Route path="/overview" component={Dashboard} />
              <Route path="/integration" component={Integrationscreen} />
              <Route path="/company-settings" component={Companysettingsscreen} />
              <Route path="/job" component={Job} />
              <Route path="/create" component={Create} />
              <Route path="/personal/project" component={ProjectPage} />
              <Route path="/in" component={JobInfo} />
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <p>
                Click on your profile name, tap on{" "}
                <strong>Switch company</strong> and a company or switch to an
                existing one
              </p>
            </div>
          )}
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

// export default AppRoutes;

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.navbarReducer.selectedCompany,
  };
};

export default connect(mapStateToProps, null)(AppRoutes);
