import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
// import dotenv from "dotenv";
import { Provider } from "react-redux";
// import store from "./redux/store";
import {PersistGate} from 'redux-persist/es/integration/react';
import createStore from './redux/store';


const { store, persistor } = createStore();


// Load environment variables
// dotenv.config();

ReactDOM.render(
  <Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
