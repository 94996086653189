import axios from "./axiosInstance";

export const fetchCompaines = async () => {
  try {
    const res = await axios.get(`/api/compaines`);
    return res?.data?.data;
  } catch (error) {
    console.log(error);
  }
};
