import { SELECTED_COMPANY } from "../types";

const initialState = {
  selectedCompany: null,
};

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_COMPANY:
      return {
        ...state,
        selectedCompany: action.payload,
      };
   
    default:
      return state;
  }
};

export default navbarReducer;
