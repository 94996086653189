/* eslint-disable no-dupe-class-members */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import axios from "../../helper/axiosInstance";
import { connect } from "react-redux";

class Sidebar extends Component {
  state = {
    allProjects: [],
  };

  toggleMenuState(menuState) {
    this.setState((prevState) => ({
      [menuState]: !prevState[menuState],
    }));
  }

  async getAllProjects() {
    try {
      const token = window.localStorage.getItem("token");
      const response = await axios.get("/api/projects", {
        headers: {
          Authorization: `${token}`,
        },
      });
      this.setState({ allProjects: response.data });
    } catch (error) {
      console.error("Error fetching projects:", error);
      // Handle the error gracefully, e.g., display an error message to the user
    }
  }

  componentWillMount() {
    this.getAllProjects();
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location !== prevProps.location) {
  //     this.onRouteChanged();
  //   }
  // }

  // onRouteChanged() {
  //   document.querySelector("#sidebar").classList.remove("active");
  //   Object.keys(this.state).forEach((i) => {
  //     this.setState({ [i]: false });
  //   });

  //   const dropdownPaths = [
  //     { path: "/apps", state: "appsMenuOpen" },
  //     { path: "/basic-ui", state: "basicUiMenuOpen" },
  //     { path: "/advanced-ui", state: "advancedUiMenuOpen" },
  //     { path: "/form-elements", state: "formElementsMenuOpen" },
  //     { path: "/tables", state: "tablesMenuOpen" },
  //     { path: "/maps", state: "mapsMenuOpen" },
  //     { path: "/icons", state: "iconsMenuOpen" },
  //     { path: "/charts", state: "chartsMenuOpen" },
  //     { path: "/user-pages", state: "userPagesMenuOpen" },
  //     { path: "/error-pages", state: "errorPagesMenuOpen" },
  //     { path: "/personal", state: "personalPage" },
  //     { path: "/ecommerce", state: "ecommercePagesMenuOpen" },
  //   ];

  //   dropdownPaths.forEach((obj) => {
  //     if (this.isPathActive(obj.path)) {
  //       this.setState({ [obj.state]: true });
  //     }
  //   });
  // }

  render() {
    const { selectedCompany } = this.props;

    return (
      <>
        {selectedCompany && (
          <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
              <li
                className={
                  this.isPathActive("/overview")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/overview">
                  <span className="menu-title">
                    <Trans>Overview</Trans>
                  </span>
                  <i className="mdi mdi-home menu-icon"></i>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive("/personal")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <div
                  className={
                    this.state.personalPage
                      ? "nav-link menu-expanded"
                      : "nav-link"
                  }
                  onClick={() => this.toggleMenuState("personalPage")}
                  data-toggle="collapse"
                >
                  <span className="menu-title">
                    <Trans>Job Board</Trans>
                  </span>
                  <i className="menu-arrow"></i>
                  <i className="mdi mdi-medical-bag menu-icon"></i>
                </div>
                <Collapse in={this.state.personalPage}>
                  <ul className="nav flex-column sub-menu">
                    <li className="nav-item">
                      <Link
                        className={
                          this.isPathActive(`/job/`)
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={`/job/`}
                      >
                        <div>
                          <h6>Jobs</h6>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
                {/* <Collapse in={this.state.personalPage}>
              <ul className="nav flex-column sub-menu">
                {allProjects?.data?.map((project) => (
                  <li className="nav-item" key={project._id}>
                    <Link
                      className={
                        this.isPathActive(`/personal/`)
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to={`/personal/project/${project._id}`}
                    >
                      <div>
                        <h6>{project.project_name}</h6>
                        <p className="font-weight-light">
                          {project.project_type}
                        </p>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapse> */}
              </li>
            </ul>
            <div className="bottom_sidebar ">
              <Link
                className={
                  this.isPathActive(`/integration`)
                    ? "nav-link active"
                    : "nav-link"
                }
                to={`/integration`}
              >
                <p>Integration</p>
              </Link>
              <Link
                className={
                  this.isPathActive(`/company-settings`)
                    ? "nav-link active"
                    : "nav-link"
                }
                to={`/company-settings`}
              >
                <p>Company settings</p>
              </Link>
            </div>
          </nav>
        )}
      </>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    // this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.navbarReducer.selectedCompany,
  };
};

export default connect(mapStateToProps, null)(withRouter(Sidebar));
