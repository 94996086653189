import {combineReducers} from 'redux';
import navbarReducer from './general/navbarReducer';


const combinedReducerState = combineReducers({
  navbarReducer: navbarReducer,
});
const rootReducer = (state, action) => {
  // if (action.type === 'logout') {
  //   state = undefined;
  // }
  return combinedReducerState(state, action);
};

export default rootReducer;
