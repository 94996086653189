import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './rootReducer';
import { composeWithDevTools } from '@redux-devtools/extension';

// import AsyncStorage from '@react-native-async-storage/async-storage';

export const persistConfig = {
  key: 'root',
  storage,
  debug: true, // to get useful logging
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default (data) => {
  const store = createStore(
    persistedReducer,
    data,
    composeWithDevTools(applyMiddleware(thunk)),
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
