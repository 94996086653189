import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import axios from "../../helper/axiosInstance";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import { selectedCompanyEvent } from "../../redux/actions";
import Image from "react-bootstrap/Image";
import { useLocation } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import countryCodes from "../../helper/country_codes.json";
import Toast from "react-bootstrap/Toast";
import { fetchCompaines } from "../../helper/api";

const Navbar = (props) => {
  const [isMe, setMe] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [show, setShow] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const location = useLocation();
  const [validated, setValidated] = useState(false);
  const [isCode, setCode] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isMessage, setMessage] = useState("");

  const [isCompanyName, setCompanyName] = useState("");
  const [isWebsite, setWebsite] = useState("");
  const [isNumber, setNumber] = useState("");

  const cloudinary_url = process.env.REACT_APP_CLOUDINARY_SECURE_URL;
  const cloudinary_image = process.env.REACT_APP_CLOUDINARY_IMAGE;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { selectedCompany, selectedCompanyEvent } = props;

  const getAllCompines = async () => {
    const data = await fetchCompaines();
    setCompanies(data);
  };

  useEffect(() => {
    //  me
    const userInfo = async () => {
      try {
        const response = await axios.get(`/api/me`);
        setMe(response?.data?.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
        // Handle the error gracefully, e.g., display an error message to the user
      }
    };

    // if (location.pathname === "/dashboard") {
    userInfo();
    getAllCompines();
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    const formData = new FormData();
    formData.append("company_name", isCompanyName);
    formData.append("website", isWebsite);
    formData.append("number", `${isCode}${isNumber}`);

    try {
      event.preventDefault();
      setLoading(true);
      await axios.post("/api/compaines", formData).then((response) => {
        if (response?.data?.status === "success") {
          setSuccess(true);
          setLoading(false);
          setMessage(response?.data?.status);
          getAllCompines();
        }
      });
    } catch (error) {
      setFailed(true);
      setLoading(false);
      setMessage(error);
    }
  };

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };

  const toggleCompany = (value) => {
    selectedCompanyEvent(value);
  };

  const selectedCompanyNanme = companies.find(
    (el) => el.slug === selectedCompany
  );

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <div className="pl-2 navbar_form_select">
          <h4>{selectedCompanyNanme?.company_name || "Select company"}</h4>
        </div>
        {location.pathname !== "/integration" && (
          <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search job"
                />
              </div>
            </form>
          </div>
        )}
        <ul className="navbar-nav navbar-nav-right">
          {selectedCompany && (
            <li className="nav-item nav-logout d-none d-lg-block">
              <Link className="nav-link" to="/create">
                <p className="mb-1 text-black font-weight-bold">
                  Create a new job
                </p>
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol bg-danger"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0">
                  <Trans>Notifications</Trans>
                </h6>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Event today</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <Trans>
                        Just a reminder that you have an event today
                      </Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-warning">
                      <i className="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Settings</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <Trans>Update dashboard</Trans>
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <Dropdown.Item
                  className="dropdown-item preview-item"
                  onClick={(evt) => evt.preventDefault()}
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-info">
                      <i className="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 className="preview-subject font-weight-normal mb-1">
                      <Trans>Launch Admin</Trans>
                    </h6>
                    <p className="text-gray ellipsis mb-0">
                      <Trans>New admin wow</Trans>!
                    </p>
                  </div>
                </Dropdown.Item>
                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  <Trans>See all notifications</Trans>
                </h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item nav-settings d-none d-lg-block">
            <button
              type="button"
              className="nav-link border-0"
              onClick={toggleRightSidebar}
            >
              <i className="mdi mdi-format-line-spacing"></i>
            </button>
          </li>
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">
                    <Trans>{`${isMe?.first_name} ${isMe?.last_name}`}</Trans>
                  </p>
                </div>
                <div className="nav-profile-img">
                  <Image
                    src={`${cloudinary_url}/${cloudinary_image}/upload/v${isMe?.upload_details?.version}/${isMe?.upload_details?.folder}/${isMe?.photo}`}
                    roundedCircle
                  />
                  <span className="availability-status online"></span>
                </div>
              </Dropdown.Toggle>

              {showCompany === false && (
                <Dropdown.Menu className="navbar-dropdown w-100 first_dropdown">
                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    <Trans>Settings</Trans>
                  </Dropdown.Item>
                  <button
                    className="dropdown-item"
                    onClick={() => setShowCompany(true)}
                  >
                    <i className="mdi mdi-office-building mr-2 text-success"></i>

                    <Trans>Switch company</Trans>
                  </button>

                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                  >
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Signout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
              {showCompany && (
                <section className="navbar-dropdown w-100 list_company w-50">
                  <button
                    className="mt-0 d-flex align-items-center"
                    onClick={() => setShowCompany(false)}
                  >
                    <svg viewBox="0 0 448 512" fill="black" height="1em">
                      <path d="M7.4 273.4C2.7 268.8 0 262.6 0 256s2.7-12.8 7.4-17.4l176-168c9.6-9.2 24.8-8.8 33.9 .8s8.8 24.8-.8 33.9L83.9 232 424 232c13.3 0 24 10.7 24 24s-10.7 24-24 24L83.9 280 216.6 406.6c9.6 9.2 9.9 24.3 .8 33.9s-24.3 9.9-33.9 .8l-176-168z"></path>
                    </svg>
                    <span className="pl-2">Back</span>
                  </button>
                  <div className="availbal_companies">
                    {companies?.map((company) => (
                      <div
                        className="comapny_name"
                        key={company?._id}
                        onClick={() => toggleCompany(company.slug)}
                      >
                        {company.company_name}
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleShow}
                    type="button"
                    className="add_company"
                  >
                    <svg
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.05263 3.55263H5.44737V0.947368C5.44737 0.424137 5.02323 0 4.5 0C3.97677 0 3.55263 0.424137 3.55263 0.947368V3.55263H0.947368C0.424137 3.55263 0 3.97677 0 4.5C0 5.02323 0.424137 5.44737 0.947368 5.44737H3.55263V8.05263C3.55263 8.57586 3.97677 9 4.5 9C5.02323 9 5.44737 8.57586 5.44737 8.05263V5.44737H8.05263C8.57586 5.44737 9 5.02323 9 4.5C9 3.97677 8.57586 3.55263 8.05263 3.55263Z"
                        fill="#1C75BB"
                      />
                    </svg>{" "}
                    Add a company
                  </button>
                </section>
              )}
            </Dropdown>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
      {isLoading === true ? (
        <div className="loading_screen">
          <div className="spinner" />
        </div>
      ) : (
        <Modal centered show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Add a company</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>Company name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Company name"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Form.Label>Website</Form.Label>
                  <InputGroup hasValidation>
                    <InputGroup.Text id="inputGroupPrepend">
                      https://
                    </InputGroup.Text>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Website"
                      onChange={(e) => setWebsite(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Label>Phone number</Form.Label>
                  <InputGroup hasValidation>
                    <select
                      className="input-group-text w-25"
                      value={isCode}
                      onChange={(e) => setCode(e.target.value)}
                    >
                      {countryCodes?.map((type) => (
                        <option key={type.code} value={type.dial_code}>
                          {type.dial_code} {type.name}
                        </option>
                      ))}
                    </select>
                    <Form.Control
                      type="text"
                      placeholder="Phone number"
                      required
                      onChange={(e) => setNumber(e.target.value)}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid city.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button className="w-100" type="submit" onClick={handleClose}>
                Add company
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      <Toast
        className="success_toast"
        onClose={() => setSuccess(false)}
        show={isSuccess}
        delay={3000}
        autohide
      >
        <Toast.Body>{isMessage}</Toast.Body>
      </Toast>
      <Toast
        className="failed_toast"
        onClose={() => setFailed(false)}
        show={isFailed}
        delay={3000}
        autohide
      >
        <Toast.Body>{isMessage}</Toast.Body>
      </Toast>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.navbarReducer.selectedCompany,
  };
};

const mapDispatchToProps = {
  selectedCompanyEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
