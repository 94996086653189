import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "../../helper/axiosInstance";
import Toast from "react-bootstrap/Toast";
import employmentTypes from "../../helper/employment_type.json";
import departmentTypes from "../../helper/department_type.json";
import experienceTypes from "../../helper/experience_type.json";
import educationTypes from "../../helper/education_type.json";
import industryTypes from "../../helper/industry_type.json";

const country_state_city = process.env.REACT_APP_COUNTRY_STATE_CITY;
const country_state_city_api_key =
  process.env.REACT_APP_COUNTRY_STATE_CITY_API_KEY;

function UpdateJobPanel({ selectedJob }) {
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [isDescription, setDescription] = useState("");
  const [isRequirements, setRequirements] = useState("");
  const [isBenefits, setBenefits] = useState("");
  const [isWorkplace, setWorkplace] = useState(null);
  const [isEmployment, setEmployment] = useState("");
  const [isDepartment, setDepartment] = useState("");
  const [isExperience, setExperience] = useState("");
  const [isEducation, setEducation] = useState("");
  const [isIndustry, setIndustry] = useState("");
  const [isCountries, setCountries] = useState([]);
  const [isState, setState] = useState([]);
  const [isSeleectedState, setSeleectedState] = useState("");
  const [isSelectedCountry, setSelectedCountry] = useState("");

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isMessage, setMessage] = useState("");

  // console.log("dddddd", isSeleectedState)
  

  const closeRightSidebar = () => {
    document.querySelector(".job-sidebar").classList.remove("open");
    //alert("abc")
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setLoading(false);
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    // Prepare data for the POST request

    const formData = new FormData();

    formData.append("title", fullName);
    formData.append("description", isDescription);
    formData.append("requirements", isRequirements);
    formData.append("benefits", isBenefits);
    formData.append("workplace", isWorkplace);
    formData.append("employment_type", isEmployment);
    formData.append("department", isDepartment);
    formData.append("experience", isExperience);
    formData.append("education", isEducation);
    formData.append("industry", isIndustry);
    if (isWorkplace !== "Remote") {
      formData.append("country", isSelectedCountry);
      formData.append("city", isSeleectedState);
    }

    try {
      const company_slug = window.localStorage.getItem("@company_slug");
      event.preventDefault();
      setLoading(true);
      await axios
        .patch(`/api/jobs/${company_slug}/${selectedJob._id}`, formData)
        .then((response) => {
          if (response?.data?.status === "success") {
            setSuccess(true);
            setLoading(false);
            setMessage(response?.data?.status);
          }
        });
    } catch (error) {
      setFailed(true);
      setLoading(false);
      setMessage(error);
      console.log(error);
    } finally {
      setTimeout(() => {
        // Reload the page after successful update
        window.location.reload();
      }, 2000);
    }
  };

  useEffect(() => {
    async function listOfCountries() {
      try {
        const response = await axios.get(`${country_state_city}/countries`, {
          headers: {
            "X-CSCAPI-KEY": country_state_city_api_key,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          redirect: "follow",
        });
        setCountries(response?.data);
      } catch (error) {
        console.error(error);
      }
    }

    listOfCountries();
  }, []);

  useEffect(() => {
    const {
      description,
      requirements,
      benefits,
      workplace,
      employment_type,
      department,
      experience,
      education,
      industry,
      country,
      city,
    } = selectedJob || {};

    if (description) setDescription(description);
    if (requirements) setRequirements(requirements);
    if (benefits) setBenefits(benefits);
    if (workplace) setWorkplace(workplace);
    if (employment_type) setEmployment(employment_type);
    if (department) setDepartment(department);
    if (experience) setExperience(experience);
    if (education) setEducation(education);
    if (industry) setIndustry(industry);
    if (country) setSelectedCountry(country);
    if (city) setSeleectedState(city);

  }, [selectedJob]);

  const onSelectedCountry = (selected) => {
    setSelectedCountry(selected);

    const selectedCountry = isCountries.find(
      (country) => country.name === selected
    );

    axios
      .get(`${country_state_city}/countries/${selectedCountry.iso2}/states`, {
        headers: {
          "X-CSCAPI-KEY": country_state_city_api_key,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        redirect: "follow",
      })
      .then(function (response) {
        // handle success
        setState(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const getSuccessMessage = (type) => {
    switch (type) {
      case "Remote":
        return "Employees work from home";
      case "Hybrid":
        return "Employees work from both office and home";
      case "On Site":
        return "Employees work from the office";
      default:
        return "Unknown work type";
    }
  };

  return (
    <>
      {isLoading === true ? (
        <div className="loading_screen">
          <div className="spinner" />
        </div>
      ) : (
        <>
          <div id="job-sidebar" className="job-panel job-sidebar">
            <i
              className="job-close mdi mdi-close"
              onClick={closeRightSidebar}
            ></i>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="job-form">
                <Form.Group as={Col} lg="7" controlId="validationCustom01">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    contentEditable
                    required
                    type="text"
                    placeholder="Job title"
                    defaultValue={selectedJob?.title}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="12" controlId="validationCustom02">
                  <Form.Label>Description</Form.Label>
                  <div className="description_style">
                    <ReactQuill
                      theme="snow"
                      value={isDescription}
                      onChange={(value) => setDescription(value)}
                    />
                  </div>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="12" controlId="validationCustom02">
                  <Form.Label>Requirements</Form.Label>
                  <div className="description_style">
                    <ReactQuill
                      theme="snow"
                      value={isRequirements}
                      onChange={(value) => setRequirements(value)}
                    />
                  </div>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} lg="12" controlId="validationCustom02">
                  <Form.Label>Benefits</Form.Label>
                  <div className="description_style">
                    <ReactQuill
                      theme="snow"
                      value={isBenefits}
                      onChange={(value) => setBenefits(value)}
                    />
                  </div>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="pl-4 pr-4">
                  <h3 className="page-title mb-2">Workplace </h3>
                  <Row className="locate-details mt-8">
                    {["On Site", "Hybrid", "Remote"].map((type) => (
                      <div key={type} className="mb-3 location-sect">
                        <Form.Check
                          className="ml-0 mb-"
                          type="radio"
                          id={type}
                          label={type}
                          name="workplaceType"
                          checked={isWorkplace === type}
                          onChange={() => setWorkplace(type)}
                        />
                        <p className="text-black-50">
                          {getSuccessMessage(type)}
                        </p>
                      </div>
                    ))}
                  </Row>
                </Form.Group>
                <Form.Group as={Col} lg="12">
                  <h3 className="page-title mb-3 pb-3">Employment Details </h3>
                  <div className="input-section-flex">
                    <div className="input-section">
                      <div className="dropdown-section">
                        <Form.Label className="mb-2">
                          Employment type
                        </Form.Label>
                        <select
                          className="form-select"
                          value={isEmployment}
                          onChange={(e) => setEmployment(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Select employment
                          </option>
                          {employmentTypes?.map((type, id) => (
                            <option key={type.id} value={type.value}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select an option.
                      </Form.Control.Feedback>
                    </div>
                    <div className="input-section">
                      <div className="dropdown-section">
                        <Form.Label className="mb-2">Department</Form.Label>
                        <select
                          className="form-select"
                          value={isDepartment}
                          onChange={(e) => setDepartment(e.target.value)}
                          required
                        >
                          <option>Select department</option>
                          {departmentTypes.company.departments.map(
                            (department) => (
                              <optgroup
                                key={department.id}
                                label={department.name}
                              >
                                {department.sub_departments.map(
                                  (subDepartment) => (
                                    <option
                                      key={subDepartment.id}
                                      value={subDepartment.value}
                                    >
                                      {subDepartment.name}
                                    </option>
                                  )
                                )}
                              </optgroup>
                            )
                          )}
                        </select>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please select an option.
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="12"
                  className="d-flex justify-content-between"
                >
                  <div className="input-section">
                    <div className="dropdown-section">
                      <Form.Label className="mb-2">Experience</Form.Label>
                      <select
                        className="form-select"
                        value={isExperience}
                        onChange={(e) => setExperience(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select experience
                        </option>
                        {experienceTypes?.map((type, id) => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please select an option.
                    </Form.Control.Feedback>
                  </div>
                  <div className="input-section">
                    <div className="dropdown-section">
                      <Form.Label className="mb-2">Education</Form.Label>
                      <select
                        className="form-select"
                        value={isEducation}
                        onChange={(e) => setEducation(e.target.value)}
                        required
                      >
                        <option>Select education</option>
                        {educationTypes?.map((type, id) => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Form.Control.Feedback type="invalid">
                      Please select an option.
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  lg="12"
                  className="d-flex justify-content-between"
                  controlId="validationCustom10"
                >
                  <div style={{ width: "100%", marginBottom: "1rem" }}>
                    <div className="dropdown-section">
                      <Form.Label className="mb-2">Industry</Form.Label>
                      <select
                        className="form-select"
                        value={isIndustry}
                        onChange={(e) => setIndustry(e.target.value)}
                        required
                      >
                        <option>Select industry</option>
                        {industryTypes?.map((type, id) => (
                          <option key={type.id} value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </div>
                </Form.Group>
                {isWorkplace !== "Remote" && (
                  <Form.Group as={Col} lg="12">
                    <h3 className="page-title mb-3 pb-3">Location </h3>
                    <div className="input-section-flex">
                      <div className="input-section">
                        <div className="dropdown-section">
                          <Form.Label className="mb-2">Country</Form.Label>
                          <select
                            className="form-select"
                            value={isSelectedCountry}
                            onChange={(e) => onSelectedCountry(e.target.value)}
                            required={isWorkplace !== "Remote"}
                          >
                            <option>Select country</option>
                            {isCountries?.map((list) => (
                              <option key={list.id} value={list.name}>
                                {list.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please select an option.
                        </Form.Control.Feedback>
                      </div>
                      <div className="input-section">
                        <div className="dropdown-section">
                          <Form.Label className="mb-2">State</Form.Label>
                          <select
                            className="form-select"
                            value={isSeleectedState}
                            onChange={(e) => setSeleectedState(e.target.value)}
                            required={isWorkplace !== "Remote"}
                          >
                            <option>Select State</option>
                            {isState?.map((list) => (
                              <option key={list.id} value={list.name}>
                                {list.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          Please select an option.
                        </Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                )}

                <Button type="submit">Update Job</Button>
              </div>
            </Form>
          </div>
          <Toast
            className="success_toast"
            onClose={() => setSuccess(false)}
            show={isSuccess}
            delay={3000}
            autohide
          >
            <Toast.Body>{isMessage}</Toast.Body>
          </Toast>
          <Toast
            className="failed_toast"
            onClose={() => setFailed(false)}
            show={isFailed}
            delay={3000}
            autohide
          >
            <Toast.Body>{isMessage}</Toast.Body>
          </Toast>
        </>
      )}
    </>
  );
}

export default UpdateJobPanel;
